import config from '../config/config.json';

let base64 = require('base-64');

export async function getExelFile(data) {
    try {
        const  res  = await fetch(`${config.url}/excel`, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': `Basic ${base64.encode('admin:admin')}`,
            }),
            body: JSON.stringify(data)
          })
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getAuthenticated(login, password) {
    try {
        const  res  = await fetch(`${config.url}/auth/${login}/${password}`, {
            method: 'GET'
          });
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getRequestClassified(data) {
    try {
        const  res  = await fetch(`${config.url}/request_analyze`, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': `Basic ${base64.encode('admin:admin')}`,
            }),
            body: JSON.stringify(data)
        })
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getDataAnalyzed(data) {
    try {
        const  res  = await fetch(`${config.url}/research`, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': `Basic ${base64.encode('admin:admin')}`,
            }),
            body: JSON.stringify(data)
        })
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getDiagnosisOptions({signal}) {
    try {
        const  res  =  await fetch(`${config.url}/diagnosis`, {
            method: 'GET',
            signal
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getServicesOptions(language, {signal}) {
    try {
        const  res  = await fetch(`${config.url}/services/${language}`, {
            method: 'GET',
            signal
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getDeclinesOptions(language, {signal}) {
    try {
        const  res  = await fetch(`${config.url}/defects/${language}`, {
            method: 'GET',
            signal
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getApprovedTask(data) {
    try {
        const  res  = await fetch(`${config.url}/approve`, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': `Basic ${base64.encode('admin:admin')}`,
            }),
            body: JSON.stringify(data)
        })
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getOrganizationChecked(token, {signal}) {
    try {
        const  res  = await fetch(`${config.url}/organization/${token}`, {
            method: 'GET',
            signal
        });
        return res;
    } catch (error) {
        throw error;
    }
}

// YandexDisc

export async function getYandexLink() {
    try {
        const  res  = await fetch(`https://cloud-api.yandex.net/v1/disk/public/resources/download?public_key=https%3A%2F%2Fdisk.yandex.ru%2Fi%2FhEQHCACSsYE2WQ`, {
            method: 'GET',
        });
        return res;
    } catch (error) {
        throw error;
    }
}
