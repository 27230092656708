import React from "react";
import { useLocation } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";

import "./CustomMenuList.css";

export default function CustomMenuList({ children, maxHeight }) {
  const location = useLocation();
  const placeholderText = location.pathname.includes("en")
    ? "Diagnosis not found"
    : "Диагноз не найден";

  const childrenOptions = React.Children.toArray(children);
  maxHeight -= 90;
  const wrapperHeight =
    maxHeight < childrenOptions.length * 40
      ? maxHeight
      : childrenOptions.length * 40;

  const EmptyPlaceholder = () => (
    <div className="react-virtualized-menu-placeholder">{placeholderText}</div>
  );

  const renderItem = (index, item) => {
    return (
      <li key={index} style={{ background: "white" }}>
        {item}
      </li>
    );
  };

  return (
    <Virtuoso
      className="react-virtualized-list-wrapper"
      components={{ EmptyPlaceholder }}
      style={{ height: `${wrapperHeight}px` }}
      data={children}
      itemContent={renderItem}
    />
  );
}
