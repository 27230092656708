import { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Input } from "reactstrap";
import Select, { createFilter } from "react-select";

import "./diagnosis_list.css";
import diagnosisOptionsList from "../../../../dictionaries/mkb.json";
import diagnosisOptionsListEn from "../../../../dictionaries/mkb_en.json";

import Trash from "../icons/trash";
import Copy from "../icons/copy";
import Expand from "../icons/Expand";
import Compress from "../icons/Compress";
import CustomOption from "../custom_helpers/CustomOption";
import CustomMenuList from "../custom_helpers/CustomMenuList";

const options = {
  top: "0",
  minHeight: "35vh",
  height: "fit-content",
  maxHeight: "50vh",
  boxShadow: "-1px 1px 4px grey",
};

function DiagnosisList(params) {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      window.addEventListener("keydown", handleEsc);
      return () => {
        window.removeEventListener("keydown", handleEsc);
      };
    }
  });

  const handleEsc = (e) => {
    if (e.code === "Escape") {
      setIsExpanded(false);
    }
  };

  const getExpandBtnTitle = () => {
    if (params.language === "en" && isExpanded) {
      return "Shrink";
    }
    if (params.language === "en" && !isExpanded) {
      return "Expand";
    }
    if (params.language === "ru" && isExpanded) {
      return "Свернуть";
    }
    if (params.language === "ru" && !isExpanded) {
      return "Развернуть";
    }
  };

  const inputFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div title={cell}>
        <Select
          value={{ value: cell, label: cell }}
          onChange={(e) => {
            formatExtraData.onChange(rowIndex, e);
          }}
          options={
            formatExtraData.language === "en"
              ? diagnosisOptionsListEn.data
              : diagnosisOptionsList
          }
          filterOption={createFilter({ ignoreAccents: false })}
          captureMenuScroll={false}
          components={{
            Option: CustomOption,
            MenuList: CustomMenuList,
          }}
          classNamePrefix="custom-select"
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "_id",
      text: "_id",
      isKey: true,
      hidden: true,
    },
    {
      dataField: "copyName",
      text: "",
      formatExtraData:
        params.language === "en" ? "Copy diagnosis" : "Копировать название",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <button
            onClick={(e) => {
              params.copyToClipboard(row.diagnosisName);
            }}
            className={"action-icon action-icon__copy"}
            title={formatExtraData}
          >
            <Copy />
          </button>
        );
      },
      headerStyle: () => {
        return { width: "4%" };
      },
      style: {},
    },
    {
      dataField: "diagnosisName",
      text:
        params.language === "en" ? "Diagnosis name" : "Наименование диагноза",
      formatExtraData: params,
      formatter: inputFormatter,
      headerStyle: () => {
        return {
          width: "55%",
        };
      },
      style: {
        padding: "1%",
      },
    },
    {
      dataField: "removeRow",
      text: "",
      formatExtraData:
        params.language === "en" ? "Delete diagnosis" : "Удалить диагноз",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <button
            onClick={(e) => {
              params.onRemove(rowIndex);
            }}
            className={"action-icon action-icon__trash"}
            title={formatExtraData}
          >
            <Trash />
          </button>
        );
      },
      width: "9%",
      style: {
        padding: "1%",
      },
    },
    {
      dataField: "exacerbation",
      text: params.language === "en" ? "Out of exacerbation" : "Вне обострения",
      formatExtraData: params,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <Container className={"form-check exacerbation-check"}>
            <Input
              type="checkbox"
              checked={formatExtraData.data[rowIndex].exacerbation}
              className={"form-check-input"}
              onChange={() => {
                formatExtraData.onChangeExacerbation(rowIndex);
              }}
            />
          </Container>
        );
      },
      headerStyle: () => {
        return { width: "14%" };
      },
      style: {
        padding: "1%",
      },
    },
    {
      dataField: "normativeAct",
      text: params.language === "en" ? "Clinical Guideline" : "Приказ/КР",
      formatExtraData: params,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <Container
            title={formatExtraData.data[rowIndex].normativeActFullText}
          >
            <a
              href={formatExtraData.data[rowIndex].normativeActLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatExtraData.data[rowIndex].normativeAct}
            </a>
          </Container>
        );
      },
      headerStyle: () => {
        return { width: "17%" };
      },
      style: {
        padding: "1%",
      },
    },
    {
      dataField: "",
      text: "",
      headerStyle: () => {
        return { width: "2%" };
      },
      style: {
        padding: "1%",
      },
    },
  ];

  return (
    <Container
      className={"list expandable-list"}
      style={isExpanded ? options : {}}
      fluid
    >
      <div className="expand-btn-holder">
        <Button
          className="expand-btn"
          variant="outline-primary"
          size="sm"
          onClick={() => setIsExpanded(!isExpanded)}
          title={getExpandBtnTitle()}
        >
          {isExpanded ? <Compress /> : <Expand />}
        </Button>
      </div>
      <Container id="DiagnosisList" fluid>
        <BootstrapTable
          keyField="_id"
          columns={columns}
          data={params.data}
          bordered={false}
          rowClasses={"serviceCell"}
        />
        <Row className="btn-row">
          <Col md="11">
            <Button
              className={"addDiagnoseButton"}
              variant="outline-primary"
              size="sm"
              onClick={() => {
                params.addDiagnose();
                setIsExpanded(true);
              }}
            >
              {params.language === "en" ? "Add diagnosis" : "Добавить диагноз"}
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default DiagnosisList;
